var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      _c(
        "vxe-table",
        {
          ref: "fineTable",
          attrs: { data: _vm.tableData, "max-height": "500px" },
        },
        [
          _c("vxe-table-column", {
            attrs: { "min-width": "100", field: "itemName", title: "物品名称" },
          }),
          _c("vxe-table-column", {
            attrs: { "min-width": "100", field: "itemNum", title: "物品数量" },
          }),
          _c("vxe-table-column", {
            attrs: { "min-width": "120", field: "itemType", title: "物品类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.itemSubType === "REDEMPTION"
                      ? _c("span", [_vm._v("兑付奖品")])
                      : _vm._e(),
                    row.itemSubType === "GIFT"
                      ? _c("span", [_vm._v("礼品")])
                      : _vm._e(),
                    row.itemSubType === "THROW"
                      ? _c("span", [_vm._v("投放物品")])
                      : _vm._e(),
                    row.itemSubType === "PURCHASE"
                      ? _c("span", [_vm._v("采购物品")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "itemSinglePrice",
              title: "物品价格",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }