<template>
  <div class="edit-table">
    <vxe-table ref="fineTable" :data="tableData" max-height="500px">
      <vxe-table-column
        min-width="100"
        field="itemName"
        title="物品名称"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="itemNum"
        title="物品数量"
      ></vxe-table-column>
      <vxe-table-column min-width="120" field="itemType" title="物品类型">
        <template #default="{ row }">
          <span v-if="row.itemSubType === 'REDEMPTION'">兑付奖品</span>
          <span v-if="row.itemSubType === 'GIFT'">礼品</span>
          <span v-if="row.itemSubType === 'THROW'">投放物品</span>
          <span v-if="row.itemSubType === 'PURCHASE'">采购物品</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="itemSinglePrice"
        title="物品价格"
      ></vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
  },
  watch: {
    value() {
      this.tableData = this.value;
    },
  },
  data() {
    return {
      tableData: [],
    };
  },

  methods: {},
};
</script>
